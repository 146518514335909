import React, { useEffect, useReducer, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './tier-list.scss';
import lodash from 'lodash';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ILimbusCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { LimbusCharacter } from '../../modules/lc/common/components/lc-character';
import { Accordion, Card } from 'react-bootstrap';
import { LCSpecialitiesArray } from '../../modules/lc/model/lc-spec';
import Switch from 'react-switch';

interface ILimbusCharacterNodes {
  nodes: ILimbusCharacter[];
}

interface ILimbusCharacterEntry {
  allCharacters: ILimbusCharacterNodes;
}

interface IProps {
  data: ILimbusCharacterEntry;
}

const LimbusTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [currentTag, setCurrentTag] = useState(false);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search identities...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '0', value: '0' },
          { label: '00', value: '00' },
          { label: '000', value: '000' }
        ]
      },
      {
        key: 'affinity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Envy',
            tooltip: 'Envy',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_envy.png"
                width={24}
                alt="Envy"
              />
            )
          },
          {
            value: 'Gloom',
            tooltip: 'Gloom',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gloom.png"
                width={24}
                alt="Gloom"
              />
            )
          },
          {
            value: 'Gluttony',
            tooltip: 'Gluttony',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gluttony.png"
                width={24}
                alt="Gluttony"
              />
            )
          },
          {
            value: 'Lust',
            tooltip: 'Lust',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_lust.png"
                width={24}
                alt="Lust"
              />
            )
          },
          {
            value: 'Pride',
            tooltip: 'Pride',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_pride.png"
                width={24}
                alt="Pride"
              />
            )
          },
          {
            value: 'Sloth',
            tooltip: 'Sloth',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_sloth.png"
                width={24}
                alt="Sloth"
              />
            )
          },
          {
            value: 'Wrath',
            tooltip: 'Wrath',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_wrath.png"
                width={24}
                alt="Wrath"
              />
            )
          }
        ]
      },
      {
        key: 'attack_type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Blunt',
            tooltip: 'Blunt',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_blunt.png"
                width={24}
                alt="Blunt"
              />
            )
          },
          {
            value: 'Pierce',
            tooltip: 'Pierce',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_pierce.png"
                width={24}
                alt="Pierce"
              />
            )
          },
          {
            value: 'Slash',
            tooltip: 'Slash',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_slash.png"
                width={24}
                alt="Slash"
              />
            )
          }
        ]
      },
      {
        key: 'sinner',
        type: 'dropdown',
        values: [
          { label: 'Any Sinner', value: 'all' },
          {
            label: 'Don Quixote',
            value: 'Don Quixote'
          },
          {
            label: 'Faust',
            value: 'Faust'
          },
          {
            label: 'Gregor',
            value: 'Gregor'
          },
          {
            label: 'Heathcliff',
            value: 'Heathcliff'
          },
          {
            label: 'Hong Lu',
            value: 'Hong Lu'
          },
          {
            label: 'Ishmael',
            value: 'Ishmael'
          },
          {
            label: 'Meursault',
            value: 'Meursault'
          },
          {
            label: 'Outis',
            value: 'Outis'
          },
          {
            label: 'Rodion',
            value: 'Rodion'
          },
          {
            label: 'Ryoshu',
            value: 'Ryoshu'
          },
          {
            label: 'Sinclair',
            value: 'Sinclair'
          },
          {
            label: 'Yi Sang',
            value: 'Yi Sang'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: LCSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.attack_type && activeFilters.attack_type !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.attackType &&
            emp.attackType.length > 0 &&
            emp.attackType.indexOf(activeFilters.attack_type) > -1
        );
      }
      if (activeFilters.affinity && activeFilters.affinity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.affinity &&
            emp.affinity.length > 0 &&
            emp.affinity.indexOf(activeFilters.affinity) > -1
        );
      }
      if (activeFilters.sinner && activeFilters.sinner !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.baseCharacter.toLowerCase() ===
            activeFilters.sinner.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'beg', label: 'Beginner' },
    { value: 'end', label: 'Endgame' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  const tagChangeHandler = (e) => {
    setCurrentTag(e);
    forceUpdate();
  };

  return (
    <DashboardLayout className={'generic-page tier-list-limbus'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_4.jpg"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Limbus Company Tier List</h1>
          <h2>A tier list for Limbus Company.</h2>
          <p>
            Last updated: <strong>14/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Tier list" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            About the Tier List <span className="tag new">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>
                The Identities are actually decently balanced and you can make a
                lot of them work well regardless of the tier they’re in.
              </strong>
              Previously, our Tier List tried to emphasize the main strengths of
              each Identity and rank them accordingly which allowed certain IDs
              to rank high for only a specific purpose.
            </p>
            <p>
              But moving forward, we are changing our approach and there will be
              two tier lists: one focused on primarily things like Story and
              more generic content, and the other focused on content that is
              later in the game (specifically Canto 7 where Chain Battles are
              more streamlined and as such IDs that scale well there are
              favored).
            </p>
            <p>
              There’s a lot of nuance and context that has to be drawn for
              ranking an ID outside and inside their team, so we hope that with
              the separation of these two lists, it would be easier to define an
              ID inside and outside their team.
            </p>
            <p>
              <strong>Generic Tier List (Beginner)</strong> is targeted towards
              new players which will assess IDs at Uptie 3 and relatively low
              investment, meaning the IDs here either partially or does not have
              their other teammates to go with them, and will be purely rated on
              their performance alone. The IDs on this tier list will be rated
              by:
            </p>
            <ul>
              <li>Versatility</li>
              <li>Clashing</li>
              <li>Utility</li>
              <li>Availability</li>
            </ul>
            <p>
              <strong>Generic Tier List (Endgame)</strong> is targeted towards
              more experienced players at the endgame and an ID’s individual
              performance with their dedicated team. With the addition of Chain
              Battles, the IDs on this list will be rated by:
            </p>
            <ul>
              <li>Utility</li>
              <li>Damage</li>
              <li>Consistency</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Criteria <span className="tag new">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <h6>Roles</h6>
            <p>
              We compare characters within their role - so damage dealers are
              compared to other damage dealers and the ratings are adjusted
              based on their performance within the role.
            </p>
            <ul>
              <li>
                <strong className="dps">Damage Dealer:</strong> Rating is
                heavily influenced based on the ID's damage and utility to both
                deal damage and amplify the damage of others, including their
                own.
              </li>
              <li>
                <strong className="support">Status Specialist:</strong> Rating
                is heavily influenced based on the ID's ability to stack the
                count and potency of every status or brings something unique to
                that status to make it overall effective and stronger.
              </li>
              <li>
                <strong className="sustain">Support:</strong> Rating is heavily
                influenced based on the ID's ability to give buffs to allies,
                debuffs to enemies, and overall utility to help defeat enemies
                quicker.
              </li>
              <li>
                <strong className="tank">Tank:</strong> Rating is heavily
                influenced based on the ID's ability to take damage and their
                benefits to taking damage.
              </li>
            </ul>
            <h6>Generic Tier List (Beginner)</h6>
            <ul>
              <li>
                IDs will be rated independently, with minimal team synergy in
                mind. This also includes an ID’s flexibility and how it can
                perform independently, as well as whether or not an Identity’s
                teammates are required or are necessary to have to use the ID.
              </li>
              <li>
                The support passives of IDs will not be taken into account when
                rating IDs.
              </li>
              <li>Unique Story buffs are not taken into account.</li>
              <li>
                The availability of an ID will also majorly affect it’s ranking
                in this Tier List, especially if an ID relies on something that
                is exclusive to either Walpurgisnacht or Collab Event.{' '}
              </li>
              <li>
                All IDs on this Tier List will be rated at Uptie 3 (Uptie 3
                Pages are unavailable for individual IDs).
              </li>
            </ul>
            <h6>Generic Tier List (Endgame)</h6>
            <ul>
              <li>IDs will be rated using optimal team composition in mind.</li>
              <li>
                The support passives of IDs will be taken into account through
                their reviews, not the Tier List.
              </li>
              <li>
                The Utility an ID brings to a team will be taken into account.
              </li>
              <li>All IDs on this Tier List will be rated at Uptie 4</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Meta Lines and Ratings <span className="tag new">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <h6>Meta Lines</h6>
            <p>
              Meta Lines categorize each section of the tier list into a power
              bracket. Here are the four brackets:
            </p>
            <ul>
              <li>
                <strong>Best in Role Characters (SS & S+)</strong> - IDs in this
                bracket are the best of the best. They can dish out outrageous
                damage, enable a ton of synergy with other IDs, and provide
                massive buffs or debuffs with their kits. They excel in any form
                of content that this game has to offer.
              </li>
              <li>
                <strong>Meta Characters (S & A)</strong> - Characters in this
                bracket perform extremely well in content, but compared to the
                tier above them, they don't perform as well or more work is
                required for them to operate. They'll still be able to
                consistently clear all forms of content though.
              </li>
              <li>
                <strong>Niche Characters (B)</strong> - Characters in this
                bracket have the really short end of the stick, either extremely
                reliant on the team they're put in, requires a bunch of effort
                to get working, or just overall does not perform as good as the
                characters in the higher tiers.
              </li>
              <li>
                <strong>Bad Characters (C & D)</strong> - Characters in this
                bracket are just not good. Even in their best conditions they
                have absolutely nothing that makes them even remotely useful to
                the team. You'd actively be crippling yourself by using them.
              </li>
            </ul>
            <h6>Ratings</h6>
            <p>
              <strong>Generic Tier List (Beginner)</strong>
            </p>
            <ul className="no-margin">
              <li>
                <strong>SS</strong> - Absolute best in their respective
                category. Offers more than Identities in tiers below or an
                unique / core part of the kit makes them stand out.
              </li>
              <li>
                <strong>S+</strong> - Extremely exceptional IDs with really
                solid kits, although held back by their versatility or lack of
                utility that SS IDs have.
              </li>
              <li>
                <strong>S</strong> - Viable IDs that perform well and still have
                a place in the meta, but require a bit more work to truly shine.
              </li>
              <li>
                <strong>A</strong> - Still good and viable, although has a good
                number of issues or their drawbacks hold them down.
              </li>
              <li>
                <strong>B</strong> - Only viable in certain scenarios. These IDs
                has certain niches that are not good and require more investment
                to make them work.
              </li>
              <li>
                <strong>C</strong> - Average characters that have big flaws and
                struggle very hard to even play their own role.
              </li>
              <li>
                <strong>D</strong> - Detrimental, passive providers only or
                completely overshadowed by other IDs. Avoid at all costs.
              </li>
            </ul>
            <p>
              <strong>Generic Tier List (Endgame)</strong>
            </p>
            <ul className="no-margin">
              <li>
                <strong>SS</strong> - Absolute best in their respective
                category. Offers more than Identities in tiers below or an
                unique / core part of the kit makes them stand out.
              </li>
              <li>
                <strong>S+</strong> - Extremely exceptional IDs with really
                solid kits, although held back by their versatility or lack of
                utility that SS IDs have.
              </li>
              <li>
                <strong>S</strong> - Viable IDs that perform well and still have
                a place in the meta, but require a bit more work to truly shine.
              </li>
              <li>
                <strong>A</strong> - Still good and viable, although has a good
                number of issues or certain drawbacks.
              </li>
              <li>
                <strong>B</strong> - These IDs are generally okay with how they
                work but still either have a plethora of issues in regards to
                piloting or just have lesser stats.
              </li>
              <li>
                <strong>C</strong> - Average characters that have big flaws and
                struggle very hard to even play their own role.
              </li>
              <li>
                <strong>D</strong> - Detrimental, passive providers only or
                completely overshadowed by other IDs. Avoid at all costs.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h6>14.00.2025</h6>
            <p>
              Fully reworked the tier list by introducing new Beginner/Endgame
              split.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'beg' && 'Generic - Beginner'}
            {sortOption === 'end' && 'Generic - Endgame'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'beg' && 'Generic (Beginner)'}
            {sortOption === 'end' && 'Generic (Endgame)'}
          </strong>{' '}
          tier list.{' '}
          {sortOption === 'ben' &&
            'is targeted towards new players which will assess IDs at Uptie 3 and relatively low investment.'}
          {(sortOption === 'pure' || sortOption === 'pure_special') &&
            'how the character performs in the Pure Fiction. AoE is king here, followed by Blast while single target damage is mostly useless.'}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option beg ${sortOption === 'beg' && 'selected'}`}
          onClick={() => setSortOption('beg')}
        >
          <h6>Generic (Beginner)</h6>
        </div>
        <div
          className={`option end ${sortOption === 'end' && 'selected'}`}
          onClick={() => setSortOption('end')}
        >
          <h6>Generic (Endgame)</h6>
        </div>
      </div>
      <div className="employees-filter-bar limbus limbus-id">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="eidolon-switcher">
        <h5>Tier list settings</h5>
        <div className="custom-switch">
          <Switch
            checked={currentTag}
            onChange={tagChangeHandler}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
          Show tags instead of names
        </div>
      </div>
      <div className="custom-tier-list-limbus">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header support">Status Specialist</div>
            <div className="custom-header survival">Support</div>
            <div className="custom-header tank">Tank</div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header bis">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Best in Role{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-ss first ">
          <div className="tier-rating ss">
            <span>SS</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-s-plus">
          <div className="tier-rating s-plus">
            <span>S+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header bad">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Bad{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-d">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
    </DashboardLayout>
  );
};

export default LimbusTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Limbus Company | Prydwen Institute"
    description="Tier list for Limbus Company gacha game."
    game="limbus"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulLimbusCharacters(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        name
        unitId
        slug
        rarity
        baseCharacter
        attackType
        speciality
        affinity
        isNew
        isReviewPending
        tierListTags
        tierCategory
        ratings {
          beg
          end
        }
      }
    }
  }
`;
